li {
    margin-bottom: 8px;
}
li.template-bullet:before {
    color: #89B94B;
    padding-right: 15px;
    margin-left: -30px;
    vertical-align: text-top;
    width: 100%;
}

.text-li {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important;
}